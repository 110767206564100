import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { H1 } from "../elements"
import {
  Container,
  Post,
  FeatureImage,
  Cutin,
  Seo,
  Contact,
} from "../components"

const singlePost = ({ data, location }) => {
  const title = data.mdx.frontmatter.title

  return (
    <Container>
      <Seo
        pagetitle={title}
        pagedesc={`${data.mdx.frontmatter.excerpt}…`}
        pagepath={location.pathname}
        pageimg={data.mdx.frontmatter.featureImage.publicURL}
        pageimgw={
          data.mdx.frontmatter.featureImage.childImageSharp.original.width
        }
        pageimgh={
          data.mdx.frontmatter.featureImage.childImageSharp.original.height
        }
      />
      <FeatureImage
        fixed={data.mdx.frontmatter.featureImage.childImageSharp.fixed}
      />
      <Cutin />
      <Post>
        <H1>{title}</H1>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
        <Contact />
      </Post>
    </Container>
  )
}

export default singlePost

export const pageQuery = graphql`
  query SinglePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        date
        excerpt
        slug
        title
        featureImage {
          publicURL
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
            original {
              width
              height
            }
          }
        }
      }
    }
  }
`
